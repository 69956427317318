import React from "react";
import PropTypes from "prop-types";

import "./ErrorMessage.css";

ErrorMessage.defaultProps = {
    errorCode: "404",
    errorMessages: ["Not Found"],
};

ErrorMessage.propTypes = {
    errorCode: PropTypes.string.isRequired,
    errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function ErrorMessage(props) {
    return (
        <div className="errorMessage w-100 h-100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="countUp">
                            {Array.isArray(props.errorMessages) &&
                                props.errorMessages.map((errorMessage) => {
                                    return (
                                        <div
                                            className="text"
                                            key={errorMessage}
                                        >
                                            {errorMessage}
                                        </div>
                                    );
                                })}
                            {props.errorCode && (
                                <div className="number">
                                    Code: {props.errorCode}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { TextField } from "formik-material-ui";
import { InputAdornment } from "@material-ui/core";
import { Lock } from "@material-ui/icons";

const AuthType = props => {
    return (
        <TextField
            type="text"
            {...props}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Lock style={{ color: "grey" }} />
                    </InputAdornment>
                )
            }}
        ></TextField>
    );
};

export default AuthType;

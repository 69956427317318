import React, { useState } from "react";
import { TextField } from "formik-material-ui";
import { InputAdornment } from "@material-ui/core";
import { VpnKey } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

const InputPassword = props => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);
    return (
        <TextField
            type={showPassword ? "text" : "password"}
            {...props}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <VpnKey style={{ color: "grey" }} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <div
                            style={{ cursor: "pointer", color: "grey" }}
                            onClick={togglePassword}
                        >
                            {showPassword ? (
                                <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    size={"1x"}
                                ></FontAwesomeIcon>
                            ) : (
                                <FontAwesomeIcon
                                    icon={faEye}
                                    size={"1x"}
                                ></FontAwesomeIcon>
                            )}
                        </div>
                    </InputAdornment>
                )
            }}
        ></TextField>
    );
};

export default InputPassword;

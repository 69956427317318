import moment from "moment";
export const getTokenInfo = token => {
    if (token) {
        const tokenParts = token.split(".");
        const encodedPayload = tokenParts[1];
        const rawPayload = atob(encodedPayload);
        const payload = JSON.parse(rawPayload);
        payload.iat = moment.unix(payload.iat);
        payload.exp = moment.unix(payload.exp);
        return payload;
    }
    return null;
};

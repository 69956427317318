import React, { useState } from "react";

import {
    Button,
    CssBaseline,
    Link,
    Box,
    Typography,
    CircularProgress,
    MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

import { Form, Field, Formik } from "formik";
import * as Yup from "yup";

import InputPassword from "./password-input.component";
import InputUser from "./user-input.component";
import AuthType from "./auth-type-input.component";

// import { loginUri, loginLdapUri } from "../shared/uris";

import axios from "axios";
import { useCookies } from "react-cookie";

import * as authTypes from "../model/authTypes";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Powered by "}
            <Link color="inherit" href="https://www.soincon.es/">
                SOINCON
            </Link>
            <sup>&reg;</sup>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#c30117",
        height: "80px",
        width: "80px",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
    },
    submit: {
        backgroundColor: "#c30117",
        color: "white",
        "&:hover": {
            backgroundColor: "#e5011b",
        },
    },
    progress: {
        color: red[500],
    },
    error: {
        color: red[600],
        fontWeight: "bold",
        textAlign: "center",
    },
}));

export default function SignIn(props) {
    const classes = useStyles();
    const [, setCookie] = useCookies();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const { config } = props;

    const requestToken = (values) => {
        let url = "";
        switch (values.authType) {
            case authTypes.LDAP:
                url = config.AUTH_LDAP_URI;
                break;
            default:
                url = config.AUTH_URI;
                break;
        }
        return axios
            .post(url, values, {
                timeoutErrorMessage: "El servidor tardó demasiado en responder",
                timeout: 10000,
            })
            .then(
                (response) => {
                    if (response.status === 200) return response;
                    else {
                        var error = new Error(
                            `Error ${response.status}: ${response.statusText}`
                        );
                        error.response = response;
                        throw error;
                    }
                },
                (error) => {
                    let message = "";
                    switch (error.response.status) {
                        case 401:
                        case 404:
                            message =
                                "Usuario, contraseña o método de autenticación incorrectos";
                            break;
                        default:
                            message = error.message;
                            break;
                    }

                    var errMess = new Error(message);
                    throw errMess;
                }
            )
            .then((response) => response.data)
            .catch((error) => {
                setErrorMessage(error.message);
                setLoading(false);
            });
    };

    let key = "auth-type";

    const saveAuthToLS = (authType) => {
        localStorage.setItem(key, authType);
    };

    return (
        <div className="bg-white rounded login-paper ml-auto mr-auto">
            <div className="row ml-0 mr-0 pl-5 pr-5">
                <div className="col-12">
                    <CssBaseline />

                    <div className="row">
                        <div className="col-12">
                            <Typography
                                className="text-center mt-4 mb-2"
                                component="h1"
                                variant="h5"
                            >
                                Iniciar Sesión
                            </Typography>
                        </div>
                    </div>

                    <div className="row">
                        <Formik
                            initialValues={{
                                username: "",
                                password: "",
                                authType: localStorage.getItem(key) ?? "LOCAL",
                            }}
                            validationSchema={Yup.object({
                                username: Yup.string()
                                    .required("Campo Obligatorio")
                                    .min(4, "Debe tener al menos 4 caracteres"),
                                password: Yup.string().required(
                                    "Campo Obligatorio"
                                ),
                            })}
                            onSubmit={(
                                values,
                                { setSubmitting, resetForm }
                            ) => {
                                setErrorMessage(null);
                                setLoading(true);
                                setSubmitting(false);
                                saveAuthToLS(values.authType);
                                setTimeout(() => {
                                    requestToken(values).then((res) => {
                                        if (res) {
                                            setCookie("token", res.token, {
                                                path: "/",
                                            });
                                            props.redirectToWeb();
                                        }
                                    });
                                }, 1000);
                            }}
                        >
                            <Form className={classes.form}>
                                <div className="col-12 pt-2">
                                    <Field
                                        variant="outlined"
                                        fullWidth
                                        label="Usuario"
                                        name="username"
                                        autoComplete="true"
                                        component={InputUser}
                                    />
                                </div>

                                <div className="col-12 pt-2">
                                    <Field
                                        variant="outlined"
                                        fullWidth
                                        label="Contraseña"
                                        name="password"
                                        autoComplete="true"
                                        component={InputPassword}
                                    />
                                </div>

                                <div className="col-12 pt-2">
                                    <Field
                                        name="authType"
                                        required
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Autenticación"
                                        select
                                        component={AuthType}
                                    >
                                        {Object.keys(authTypes).map(
                                            (authType, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={authType}
                                                >
                                                    {authType}
                                                </MenuItem>
                                            )
                                        )}
                                    </Field>
                                </div>

                                <div className="col-12 pt-3 pb-3">
                                    {loading ? (
                                        <div className="text-center">
                                            <CircularProgress
                                                className={classes.progress}
                                            />
                                        </div>
                                    ) : (
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className={classes.submit}
                                            autoFocus
                                            disableFocusRipple
                                        >
                                            Entrar
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        </Formik>
                    </div>

                    {errorMessage && (
                        <div className="row no-gutters pt-3 pb-3">
                            <div className="col-12">
                                <div className={`${classes.error} w-100`}>
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row no-gutters">
                        <div className="col-12">
                            <Box className="mt-2 mb-3">
                                <Copyright />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

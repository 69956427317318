import React, { useEffect, useState } from "react";
import "./App.css";
import SignIn from "./components/login.component";
import { useCookies } from "react-cookie";
import { getTokenInfo } from "./shared/tokenUtils";
import moment from "moment";

import { getRequest } from "@snc/api";
import ErrorMessage from "./components/errormessage/ErrorMessage";

import { ActionProgressReport } from "@snc/ui";

function App() {
    const [cookies] = useCookies(["token"]);

    const [config, setConfig] = useState(null);

    const [isLoading, setLoading] = useState(true);
    const [redirectUri, setRedirectUri] = useState(null);
    const [configFailed, setConfigFailed] = useState(false);

    const redirectToWeb = redirectUri => window.location.replace(redirectUri);

    useEffect(() => {
        getRequest(`${process.env.PUBLIC_URL}/config.json`)
            .then(config => {
                setConfig(config);

                const params = window.location.search;
                const searchParams = new URLSearchParams(params);

                let redirectUri = config.REDIRECT;

                if (searchParams.has("redirect")) {
                    redirectUri = searchParams.get("redirect");
                }

                setRedirectUri(redirectUri);

                const token = cookies["token"];
                if (token) {
                    if (getTokenInfo(token).exp > moment()) {
                        return redirectToWeb(redirectUri);
                    }
                }

                setLoading(false);
            })
            .catch(() => {
                setConfigFailed(true);
                setLoading(false);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading)
        return (
            <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <div className=".login-paper-loading bg-white rounded">
                    <ActionProgressReport style={{ position: "relative" }} />
                </div>
            </div>
        );
    else if (configFailed) {
        return (
            <div className="w-100" style={{ height: "100vh" }}>
                <ErrorMessage
                    errorCode="404"
                    errorMessages={[
                        "Error de configuración",
                        "Contacte con su administrador"
                    ]}
                />
            </div>
        );
    } else
        return (
            <div className="container login-container justify-content-center d-flex flex-column">
                <SignIn
                    config={config}
                    redirectToWeb={() => redirectToWeb(redirectUri)}
                />
            </div>
        );
}

export default App;
